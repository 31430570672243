import "../../styles/tutoringSection.css"

import satIcon from "../../images/sat icon.svg"
import cardBg from "../../images/learning.svg"


function TuroringSection() {
  return (
    <section id="card" className="px-app-padding relative mt-10 xl:mt-0 xl:-translate-y-[20px] pb-[200px]">
            
        <div id="card-nav" className="aboslute left-0 flex gap-5 text-primary-color w-[min(600px,100%)] px-[min(55px,5%)] py-[44px] bg-white rounded-tr-[10px] rounded-tl-[10px]">
            <button className="font-[700] bg-secondary-color px-3 rounded-full">Study Abroad</button> 
            <button className="font-[700]">SAT Classes</button>    
            <button className="font-[700]">Study Visa</button> 
            <button className="font-[700]">Work Visa</button> 
            <button className="font-[700]">Other</button> 
        </div>
        
        <div className="px-[55px] bg-white pb-[55px] flex flex-wrap">
            <div className="w-[700px]">
                <div className="flex gap-5 my-[30px]">
                    <img src={satIcon} alt="" />   
                    <div className="text-primary-color">
                        <h3 className="text-[32px]">World Class Tutoring</h3> 
                        <p><span className=" after:w-[1px] after:h-[15px] after:inline-block after:bg-primary-color after:ml-[5px]">Online</span> In-person Classes Available</p>  
                    </div>  
                </div>
                <p className="md:text-[20px]">
                    Are you aspiring to attend a top-tier college or university? The SAT is a critical step on your path to higher education, and we're here to guide you towards success.
                    <br/><br/>
                    Our SAT classes are meticulously designed to help you excel in this crucial examination. Our experienced instructors bring a wealth of knowledge and expertise to the classroom, ensuring that you receive the best guidance and support.     
                </p>
                <div className="mt-[30px] md:text-[20px]">
                    <h3 className="text-primary-color">Why Choose Our SAT Classes?</h3>
                    <ul className="list-disc px-5">
                        <li className="mb-5">
                            <span className="font-bold">Expert Instructors:</span> Learn from seasoned educators who understand the SAT inside out.
                        </li>
                        <li className="mb-5">
                            <span className="font-bold">Comprehensive Curriculum:</span> Our curriculum covers all SAT sections, including Math, Reading, Writting, and Essay Writing.
                        </li>
                        <li className="mb-5">
                            <span className="font-bold">Provern Strategies:</span> Gain access to proven test-taking strategies and techniques to maximize your score.
                        </li>
                        <li className="mb-5">
                            <span className="font-bold">Small Cass Sizes:</span> Benefit from personalized attention in small, interactive classes
                        </li>
                        <li className="mb-5">
                            <span className="font-bold">Flexible Schedules:</span> We offer flexible class schedules to accommodate your busy life, with both online and in-person classes available.
                        </li>
                    </ul>
                </div>  
            </div>
            <img src={cardBg} alt="" />
        </div>
    </section>  
  )
}

export default TuroringSection