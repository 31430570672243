import heroBackground from "../../images/yellow.png"


function HeroSection() {
    return (
        <section className="flex items-start px-app-padding overflow-hidden xl:px-0 xl:pl-app-padding" >
            <div className="flex flex-col gap-20">
                <h1 className="text-primary-color text-[80px] sm:text-[143px] leading-none font-[500] xl:mt-[160px]">TRAVEL ABROAD</h1>
                <div className="p-[55px] bg-primary-color rounded-[10px] text-white relative">
                    <p className="p-[15px] text-primary-color bg-secondary-color rounded-[2px] absolute top-0 left-[min(55px,8%)] -translate-y-[50%] text-[20px] font-[600]">
                        Dare to Dream, Dare to Achieve
                    </p>
                    <p className="text-[20px]">
                        With our <span className="text-secondary-color font-[ink-free] font-[600]">guidance and expertise,</span> you'll embark on an educational adventure like no other. Whether you're aiming for top scores, exploring study abroad opportunities, or seeking visas and immigration assistance, we're here as your trusted partners. Dare to dream, and together, we'll make those dreams a reality."
                    </p>
                </div>
            </div>
            <img src={heroBackground} alt="" className="hidden xl:block w-[70%] -ml-12 -z-[1] object-contain" />
        </section>
    )
}

export default HeroSection