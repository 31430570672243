import cardCanada from "../../images/card image canada.png"
import satCompass from "../../images/sat icon compass.svg"
import cardUs from "../../images/card image US.png"

const Card = ({title, content, img}) => {
    return (
        <div className="rounded-[10px] max-w-[620px] border-2 flex flex-col gap-10 overflow-hidden bg-white">
            <img src={img} alt="" />  
            <div className="flex flex-col gap-5 px-[50px] mb-[50px] ">
                <div className="flex gap-5 items-center">
                    <img src={satCompass} alt="" />
                    <h2 className="text-[32px] text-primary-color">{title}</h2>
                </div>
                <p className="text-[20px] md:text-[25px]">{content}</p>
            </div>
        </div>
    )
}

const StudyAbroadSection = () => {
  return (
        <section className="px-app-padding pt-[150px] pb-[200px] bg-white">
            <h3 className="text-primary-color text-[32px]">Radon Consult Ghana</h3>
            <h2 className="text-primary-color text-[64px] font-[500] mb-10">Study Destinations</h2>
            <div className="flex flex-wrap gap-10 items-stretch justify-around">
              <Card
                  title="Canada" 
                  content="Canada is known for its welcoming communities, quality education, and stunning landscapes. Explore a diverse range of programs and enjoy a high quality of life in this North American gem."
                  img={cardCanada}
              />
              <Card
                  title="United States" 
                  content="Discover the land of innovation and opportunity. The United States is renowned for its world-class universities, research opportunities, and a vibrant cultural scene. Whether you're drawn to the East Coast's historic charm, the West Coast's tech hub, or the heartland's academic excellence, the U.S. offers a wide range of choices."
                  img={cardUs}
              />
            </div>
        </section>

  )
}

export default StudyAbroadSection