import StudentBanner from "../components/StudentBanner"
import TopSection from "../components/About/TopSection"
import WhyChooseUsSection from "../components/About/WhyChooseUsSection"
import CommitmentSection from "../components/About/CommitmentSection"
import BottomImg from "../components/BottomImg"

function About() {
    return (
    <>
      <StudentBanner title="ABOUT US" />
      <TopSection />
      <WhyChooseUsSection />
      <CommitmentSection />
      <BottomImg/>
    </>
  )
}

export default About