import Gustavus from "../../images/home/affiliates/gustavus 1.png"
import McMaster from "../../images/home/affiliates/mcmaster-logo-header.png"
import Uol from "../../images/home/affiliates/UofL-Horizontal-CMYK-black 1.png"
import Hamber from "../../images/home/affiliates/Humber_Logo_Black 1.png"
import Eastern from "../../images/home/affiliates/Eastern_Michigan.png"

function AffiliateSection() {
  return (
    <section className="px-app-padding py-[50px] mt-[200px] bg-white">
      <h2 className="text-center text-[70px] font-[500] text-primary-color mb-[50px]">Affiliated Schools</h2>
      <div className="flex gap-10 justify-around flex-wrap flex-center items-center">
        <img src={Gustavus} alt="" />
        <img src={McMaster} alt="" />
        <img src={Uol} alt="" />
        <img src={Hamber} alt="" />
        <img src={Eastern} alt=""/>
      </div>
    </section>
  )
}

export default AffiliateSection