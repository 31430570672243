import bottomImg from "../images/bottom_img.png"

function BottomImg() {
  return (
    <div className={" m-auto w-[90%] h-[300px] relative  mt-[200px]"}
        style={{
            backgroundImage: `url("${bottomImg}")`,
            backgroundPosition: "bottom"
        }}
    >
        <div className="absolute left-0 top-0 w-full h-full bg-[#BC000077] pt-[30px] px-[40px]">
            <h1 className="text-white text-[64px] font-[600] leading-none z-[2]">Hear From Us</h1>
            <span className="text-[24px] text-white z-[2]">Shoot us an email</span>              
        </div>
    </div>
  )
}

export default BottomImg