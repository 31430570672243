import StudentBanner from "../StudentBanner"
import Leslie from "../../images/testimonies/Leslie.png"
import Jenny from "../../images/testimonies/Jenny.png"
import dp from "../../images/testimonies/dp.png"


const TestimonyCard = ({ dp, name, profession, content, university }) => {
    return (
        <div className="border-2 bg-white rounded-[20px] p-[45px] max-w-[443px] flex flex-col gap-7">
            <div className="flex items-center gap-5">
                <img src={dp} alt="" />
                <div className="text-primary-color">
                    <h3 className="text-[24px] font-[700]">{name}</h3>
                    <span className="text-[14px]">{profession}</span>
                </div>
            </div>
            <p className="text-[20px]">{content}</p>
            <span className="mt-auto flex gap-3 items-center before:block before:rounded-full before:w-[10px] before:h-[10px] before:bg-primary-color">{university}</span>
        </div>
    )
}


function TestimonialSection() {
  return (
    <section>
        <StudentBanner/>
        <div className="px-app-padding mt-[100px]">
            <h3 className="text-[32px] text-primary-color">Testimonials</h3>
            <h1 className="text-[64px] text-primary-color font-[700] leading-none mt-[30px]">What people are saying about Radon</h1>
            <div className="flex mt-[50px] justify-around flex-wrap gap-[10px]">
                <TestimonyCard
                    name="Leslie Alexander"
                    profession="Nursing Assistant"
                    content="I can't express how grateful I am to Radon. Their guidance and support during my SAT preparation were exceptional. Thanks to their expert instructors, I achieved a score beyond my expectations. Their personal touch and commitment to my success made all the difference."
                    university="Duke University, 2020"
                    dp={Leslie}
                />
                <TestimonyCard
                    name="Jenny Wilson"
                    profession="Student"
                    content="Studying abroad was always a dream, but the process seemed overwhelming. Radon made it a breeze. They helped me find the perfect university, guided me through the application process, and even assisted with my visa. I'm now living my dream in a new country, and it's all thanks to them."
                    university="McMaster University, 2023"
                    dp={Jenny}
                />
                <TestimonyCard
                    name="Kristin Watson"
                    profession="Software Engineer"
                    content="I can't thank Radon enough for their incredible support during my immigration journey. Their knowledge of the process and dedication to my case were remarkable. They turned a complex procedure into a smooth, stress-free experience."
                    university="Working in Canada, 2023"
                    dp={dp}
                />
            </div>
        </div>
    </section>

  )
}

export default TestimonialSection