import HeroSection from "../components/Home/HeroSection"
import TutoringSection from "../components/Home/TutoringSection"
import ArticleSection from "../components/Home/ArticleSection"
import StudyAbroadSection from "../components/Home/StudyAbroadSection"
import TestimonialSection from "../components/Home/TestimonialSection"
import AffiliateSection from "../components/Home/AffiliateSection"
import BottomImg from "../components/BottomImg"

function Home() {
  return (
    <>
      <HeroSection />
      <TutoringSection />
      <ArticleSection />
      <StudyAbroadSection />
      <TestimonialSection />
      <AffiliateSection />
      <BottomImg/>
    </>
  )
}

export default Home