import "../styles/studentBanner.css"

function StudentBanner({ title, subTitle }) {
  return (
    <div id="student-bg" className={`h-[280px] w-full bg-no-repeat bg-cover mb-10 ${title ? "mb-20" : ""}`}>
      {title &&
        <div className="-translate-y-[50%] w-[min(30%)] px-[min(70px,7vw)] py-[20px] bg-white min-w-fit m-auto rounded-[10px] shadow-black shadow-sm">
          <h1 className="text-[50px] text-primary-color sm:text-[72px] text-center">{title}</h1>
          {subTitle && <h3>{subTitle}</h3>}
        </div>
      }
    </div>
  )
}

export default StudentBanner