import './App.css';
import { HashRouter as Router, Routes, Route } from "react-router-dom"

import Template from "./Template"
import Home from "./pages/Home"
import About from "./pages/About"
import Apply from "./pages/Apply"

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={Template}>
          <Route index Component={Home} />
          <Route path="/about" Component={About} />
          <Route path="/apply" Component={Apply} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
