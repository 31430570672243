import logo from "../images/logo.svg"
import instagram from "../images/socials/instagram.svg"
import twitter from "../images/socials/twitter.svg"
import facebook from "../images/socials/facebook.svg"
import youtube from "../images/socials/youtube.svg"

import {Link} from "react-router-dom"

function Footer() {
  return (
      <div className='bg-primary-color text-white px-app-padding pt-[50px]'>
        <div className="flex justify-between flex-wrap gap-10">
            <div className="flex flex-col items-center gap-5">
                <Link to="/"><img src={logo} alt="" /></Link>
                <div className="flex gap-5 items-center justify-between">
                    <a href="."> <img src={instagram} alt="" /></a>
                    <a href="."> <img src={twitter} alt="" />
 </a>                   <a href="."> <img src={facebook} alt="" />
</a>                    <a href="."> <img src={youtube} alt="" />
 </a>               </div>
            </div>
              <div>
                  <Link to="/about"><h4>Who We Are</h4></Link>
                  <Link to="/apply"><h4>Apply To Study</h4></Link>
              </div>
              <div>
                  <h4 className="text-[#D74C4C]">Find Us</h4>
                  <p><span className="after:inline-block after:h-[15px] after:w-[1px] after:bg-white after:mx-1">Accra</span>Kumasi - Ghana</p>
                  <h4>Virginia - USA</h4>
              </div>
              <div>
                  <h4 className="text-[#D74C4C]">Get In Touch</h4>
                  <p><a className="after:inline-block after:h-[15px] after:w-[1px] after:bg-white after:mx-1" href="tel:+233240894565">+233 (0) 240 894 565</a> <a href="tel:+233593694601">+233 (0) 593 694 601</a></p>
                  <a href="mailto:info@radonghana.com">info@radonghana.com</a>
            </div>
          </div>
          <hr className="my-5" />
          <div className="flex justify-between pb-5">
              <span>© 2023 Radon Consult Ghana</span>
              <span>Radon Ghana</span>
          </div>
    </div>
  )
}

export default Footer