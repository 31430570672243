import {Link} from "react-router-dom"
import Logo from "../images/logo.svg"

function Header() {
  return (
    <div className="h-[120px] flex items-center justify-between bg-primary-color px-app-padding w-full">
        <Link to="/"><img src={Logo} alt="Radon" /></Link>
        <div className="flex gap-[20px] items-center">
            <Link to="/apply" className="text-primary-color bg-secondary-color rounded-[17px] py-[14px] px-[33px]">
                APPLY NOW
            </Link>
              
            <svg width="69" height="24" viewBox="0 0 69 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="68.3479" height="8.86957" rx="4.43479" fill="#FEFEFE"/>
                <rect y="15.1304" width="68.3479" height="8.86957" rx="4.43479" fill="#FEFEFE"/>
            </svg>
        </div>
    </div>
  )
}

export default Header