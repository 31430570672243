import {Link} from "react-router-dom"
import studentBgImg from "../../images/student-bg-img.png"

function ArticleSection() {
  return (
    <section className=" justify-between items-center pt-[200px] bg-white px-app-padding xl:flex xl:gap-10">
        <img className="m-auto" src={studentBgImg} alt="" />
        <div className="">
            <h3 className="text-primary-color text-[32px] mb-[50px] md:text-[40px]">
                At Radon Consult, our unwavering <span className="font-[ink-free] text-black font-[600]">commitment</span> is to empower individuals like you to reach their full potential and achieve their dreams.
            </h3>
            <p className="text-[20px] md:text-[25px]">
                We firmly believe that education serves as the bedrock of personal and professional advancement. With a resolute dedication to your success, we pledge to be your trusted navigators on the path to achieving your goals.
                <br/><br/>
                Our principles are anchored in unwavering integrity, a relentless pursuit of excellence, and a client-centric ethos. These values are the pillars upon which we build our reputation. Our mission is clear: to deliver unparalleled educational and immigration services of the highest caliber, meticulously tailored to your unique ambitions.
                <br/><br/>
                As you journey with us, rest assured that your dreams are our shared vision, and together, we are poised to craft a future brimming with achievements."
            </p>
            <Link to="/about"  className="inline-block mt-[30px] border-b-[3px] border-b-secondary-color border-solid text-primary-color text-[25px]" href="">Read More &rarr; </Link>
        </div>
    </section>
  )
}

export default ArticleSection