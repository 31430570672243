import compass from "../../images/sat icon compass.svg"

const Card = ({ title, content }) => {
    return (
        <div className="max-w-[550px] p-10 bg-white rounded-[10px] border-2">
            <div className="flex gap-5 mb-[30px]">
                <img src={compass} alt="" />
                <h3 className="text-[32px] text-primary-color">{title}</h3>
            </div>
            <p className="text-[24px]">{content}</p>
        </div>
    )
}

function TopSection() {
    return (
      <section className="px-app-padding">
        <p className='my-[200px] text-[32px]'>
            Are you aspiring to attend a top-tier college or university? The SAT is a critical step on your path to higher education, and we're here to guide you towards success.
            <br /><br />
            Our SAT classes are meticulously designed to help you excel in this crucial examination. Our experienced instructors bring a wealth of knowledge and expertise to the classroom, ensuring that you receive the best guidance and support.
        </p>
        <div className="flex justify-around gap-5 flex-wrap">
            <Card
                    title="Our Mission"
                    content={
                        <p>To empower individuals like you to reach your full potential and achieve your dreams. Whether you're a student aspiring to excel academically, a dreamer seeking to study abroad, or an individual pursuing visa and immigration goals, we are here to guide you on your journey toward success."
                        </p>}
            />
            <Card
                title="Our Values"
                content={
                    <ul className="list-disc ml-7">
                        <li><span>Integrity: </span>We follow the greatest ethical standards while offering our cliens with honest advice.</li>
                        <li><span>Excellence: </span> We always strive for excellent service, exceeding expectations with outstanding outcomes.</li>
                        <li><span>Client-Centric: </span> We are devoted to addressing your needs and goals in all interactions.</li>
                    </ul>
                }
            />
        </div>
    </section>
  )
}

export default TopSection