import booksBackground from "../../images/about/old-books-arrangement-with-copy-space 1.png"

function CommitmentSection() {
  return (
    <section>
          <div className='relative before:absolute before:top-0 h-[250px] mt-[150px] before:block before:w-full before:h-full before:bg-[#4b0000bb] bg-no-repeat'
            style={{
                backgroundImage: `url("${booksBackground}")`,
                backgroundSize: "cover",
                backgroundPosition: "center bottom"
            }}
          >
          </div>
        <div className="bg-white rounded-[10px] shadow-sm shadow-black px-[10%] py-[100px] mx-auto w-[90%] -translate-y-[100px]">
              <p className="text-primary-color text-[36px]">
                  At Radon Consult, our unwavering <span className="text-black font-[ink-free] font-[600]">commitment </span>is to empower individuals like you to reach their full potential and achieve their dreams.
              </p>
              <p className="text-[24px] mt-20">
                  We firmly believe that education serves as the bedrock of personal and professional advancement. With a resolute dedication to your success, we pledge to be your trusted navigators on the path to achieving your goals.
<br /><br />
Our principles are anchored in unwavering integrity, a relentless pursuit of excellence, and a client-centric ethos. These values are the pillars upon which we build our reputation. Our mission is clear: to deliver unparalleled educational and immigration services of the highest caliber, meticulously tailored to your unique ambitions.
<br /><br />
As you journey with us, rest assured that your dreams are our shared vision, and together, we are poised to craft a future brimming with achievements."
              </p>
        </div>
    </section>
  )
}

export default CommitmentSection