import StudentBanner from "../components/StudentBanner"

function Apply() {
  return (
    <>
      <StudentBanner
        title="APPLICATION"
        subTitle="Submit a form and will get back to you within 24 hours"
      />
      <div className="relative overflow-scroll">
        <iframe
          id="JotFormIFrame-213502382215546"
          title="RADON Application Form"
          onload="window.parent.scrollTo(0,0)"
          allowtransparency="true"
          allowfullscreen="true"
          allow="geolocation; microphone; camera"
          src="https://form.jotform.com/213502382215546"
          frameborder="0"
          style={{ minWidth: "100%", maxWidth: "100%", height: "1500px", border: "none" }}
          scrolling="yes"
        >
        </iframe>
      </div>
    </>
  )
}

export default Apply