import studentImg from "../../images/student_img.png"
import star from "../../images/about/star_badge.png"
import sixPins from "../../images/about/six_pins_badge.png"
import fourPins from "../../images/about/four_pins_badge.png"
import partner from "../../images/about/patner_badge.png"

const Card = ({img, title, content}) => {
    return (
        <div className="w-[400px] p-[30px] pt-[50px] relative mt-[60px] bg-[#FBD1D1] rounded-[15px] m-auto lg:m-0 lg:mt-[60px]">
            <img className="absolute top-0 left-[30px] -translate-y-[50%]" src={img} alt="" />
            <h3 className="pb-5 text-[24px] font-[800] mt-5">{title}</h3>
            <p className="text-[20px]">{content}</p>
        </div>
    )
}

function WhyChooseUsSection() {
  return (
    <section className='lg:flex justify-between items-center gap-20 text-primary-color px-app-padding mt-[150px]'>
        <img className="m-auto" src={studentImg} alt="" />
        <div>
            <div className="mt-[70px] flex gap-5 items-center ">
                <img src={star} alt=""/>
                <h3 className="text-[45px] font-[800]">Why Choose Us</h3>
            </div>
            <p className="text-[28px] my-[30px]">At Radon Consult, we stand out as your trusted partners on your educational and immigration journey. We offer:</p>
            <div className="flex flex-wrap gap-5">
                <Card
                    img={sixPins}
                    title="Personalized Guidance"
                    content="We recognize that every individual is unique, and so are your goals. Our personalized approach ensures that you receive the guidance and support that aligns perfectly with your aspirations."
                />
                <Card
                    img={fourPins}
                    title="Proven Results"
                    content="Our track record speaks for itself. Our clients have achieved remarkable SAT scores, secured admissions to prestigious institutions, and successfully navigated visa and immigration processes."
                />
                <Card
                    img={partner}
                    title="A Partner in Your Journey"
                    content="We're not just a consultancy; we're your partners on the path to success. Your success is our success, and we're dedicated to seeing you thrive"
                />
            </div>
        </div>
    </section>
  )
}

export default WhyChooseUsSection